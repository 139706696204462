import { ComponentProps, ReactNode } from "react";
import { clsx } from "clsx";
import { Spinner } from "./Spinner";

type Props = {
  children: ReactNode;
  pending?: boolean;
} & ComponentProps<"button">;

export const Button = ({
  children,
  className,
  type = "button",
  disabled,
  pending = false,
  ...rest
}: Props) => {
  return (
    <button
      className={clsx(
        "bg-teal-500 hover:bg-teal-400 text-white font-bold py-2 px-4 rounded flex items-center justify-center gap-2",
        className
      )}
      type={type}
      disabled={disabled || pending}
      {...rest}
    >
      <Spinner enabled={pending} size="sm" />
      {children}
    </button>
  );
};

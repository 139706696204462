import { clsx } from "clsx";
import { ComponentProps } from "react";

type Props = ComponentProps<"input">;

export const Input = ({ className, ...rest }: Props) => {
  return (
    <input
      className={clsx("border border-gray-300 rounded-md px-4 py-2", className)}
      {...rest}
    />
  );
};

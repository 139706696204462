import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions,
  useQueryClient,
} from "@tanstack/react-query";

type QueryOptions = Omit<UseQueryOptions, "queryKey" | "queryFn">;
type MutationOptions<Request, Response> = Omit<
  UseMutationOptions<Response, Error, Request>,
  "mutationFn"
> & { invalidateQueryKey?: Array<string> };

export type CreateSubscriptionRequest = { email: string; };
export type CreateSubscriptionResponse = Promise<{ subscription: { id: string; email: string; }; }>;

const mutationCreateSubscription = async (body: CreateSubscriptionRequest) => {
  const res = await fetch("/api/mutation?m=CreateSubscription", {
    method: "POST",
    body: JSON.stringify(body),
  });
  return handleResponse<Awaited<CreateSubscriptionResponse>>(res);
};

export const useMutationCreateSubscription = ({
  invalidateQueryKey,
  ...params
}: MutationOptions<CreateSubscriptionRequest, Awaited<CreateSubscriptionResponse>>) => {
  const queryClient = useQueryClient();
  return useMutation({
    ...params,
    mutationFn: mutationCreateSubscription,
    onSuccess: (...res) => {
      params.onSuccess?.(...res);
      if (invalidateQueryKey) {
        queryClient.invalidateQueries({ queryKey: invalidateQueryKey });
      }
    },
  });
};

  async function handleResponse<Res>(res: Response) {
  if (!res.ok) {
    console.error(res.statusText);
    const text = await res.text();
    console.error(res.statusText, text);
    throw new Error(`${res.statusText}: ${text}`);
  }
  return res.json() as Promise<Res>;
}

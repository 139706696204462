"use client";
import { FormEvent, useCallback, useState } from "react";
import { Button } from "./utilities/Button";
import { Input } from "./utilities/Input";
import { useMutationCreateSubscription } from "@workspace/queries";

export const SignUp = () => {
  const [email, setEmail] = useState("");
  const addSubscriber = useMutationCreateSubscription({});

  if (addSubscriber.isSuccess) {
    return (
      <div className="w-96 flex flex-col gap-2 text-center min-h-36 text-secondary">
        <p>Thank you for signing up!</p>
        <p>We will send you an email when we launch.</p>
      </div>
    );
  }

  return (
    <form
      className="w-64 flex flex-col gap-3 min-h-36"
      onSubmit={(event: FormEvent) => {
        event.preventDefault();
        addSubscriber.mutate({ email });
      }}
      method="POST"
      target="#"
    >
      <Input
        placeholder="Email"
        required
        type="email"
        name="email"
        value={email}
        onChange={(e) => setEmail(e.currentTarget.value)}
        autoComplete="email"
      />
      {addSubscriber.error?.message ? (
        <p className="text-red-800">{addSubscriber.error.message}</p>
      ) : null}
      <Button className="w-64" type="submit" pending={addSubscriber.isPending}>
        Sign Up
      </Button>
    </form>
  );
};
